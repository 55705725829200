import React from "react";
import Img, { FluidObject } from "gatsby-image";

import styles from "./food-menu.module.scss";

type FoodMenuProps = {
  fluids: FluidObject[];
};

export const FoodMenu: React.FC<FoodMenuProps> = ({ fluids }) => (
  <div className={styles.wrapper}>
    <div className={styles.innerWrapper}>
      {fluids.map((fluid, i) => (
        <Img fluid={fluid} draggable={false} key={i} />
      ))}
    </div>
  </div>
);

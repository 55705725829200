import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { Layout } from "../../components/shared/layout";
import { Helmet } from "../../components/shared/helmet";
import { PageTitle } from "../../components/shared/page-title";

import { FoodMenu } from "../../components/speisekarte/food-menu";
import { FullHeight } from "../../components/shared/full-height";

export default () => {
  const { file0, file1 } = useStaticQuery(graphql`
    query {
      file0: file(relativePath: { eq: "speisekarte-petit-cafe-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      file1: file(relativePath: { eq: "speisekarte-petit-cafe-02.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Helmet title="Petit Café - Speisekarte" />
      <PageTitle>
        PETIT CAFÉ
        <br />
        SPEISEKARTE
      </PageTitle>
      <FullHeight>
        <FoodMenu
          fluids={[file0, file1].map(file => file.childImageSharp.fluid)}
        />
      </FullHeight>
    </Layout>
  );
};
